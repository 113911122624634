import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Main from "../components/layout/Main.vue";
import Home from "../views/Home.vue";
import Login from "../Login.vue";
import helper from "../utils/helpers";
import webSiteOptions from "../utils/enums/webSiteOptions";

Vue.use(VueRouter);
// console
let routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      //aca hago la vuelta del mvc al vue
      const goTo = localStorage.getItem("goto");
      if (goTo) {
        localStorage.removeItem("goto");
        next(goTo);
      }
      next();
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresAuth: false },
    beforeEnter(to, from, next) {
      const token = localStorage.getItem("token");
      const cookies = helper.getCookie("gecrosgestioncookie");
      if (token && cookies) {
        next("/");
      }
      next();
    }
  },
  {
    path: "/",
    name: "main",
    component: Main,
    meta: { requiresAuth: true },
    children: [
      /* PRESTADORES */
      {
        //OPTION
        path: "prestadores/configuracion",
        name: "ConfiguracionPrestadores",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Prestadores");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.CONFIGURACION_PRESTADORES
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import("../views/modules/prestadores/ConfiguracionPrestadores.vue")
      },
      {
        // VIEW
        path: "prestadores/especialidades",
        name: "Especialidades",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Prestadores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.ESPECIALIDADES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../components/modules/prestadores/Especialidades.vue")
      },
      {
        // VIEW
        path: "prestadores/grupos-entidades-efectoras",
        name: "GruposEntidadesEfectoras",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Prestadores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.GRUPOS_ENTIDADES_EFECTORAS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/prestadores/GruposEntidadesEfectoras.vue"
          )
      },
      {
        // VIEW
        path: "prestadores/alfabeta",
        name: "ProcesoActAlfaBeta",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Prestadores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.PROCESOS_ACT_ALFA_BETA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../components/modules/prestadores/ProcesoActAlfaBeta.vue")
      },
      {
        // VIEW
        path: "prestadores/tipos-prestadores",
        name: "TipoPrestadores",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Prestadores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.TIPOS_PRESTADORES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../components/modules/prestadores/TipoPrestadores.vue")
      },
      {
        //VIEW
        path: "prestadores/config-cartilla",
        name: "ConfiguracionCartilla",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Prestadores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIGURACION_CARTILLA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../views/modules/prestadores/ConfiguracionCartilla.vue")
      },

      /* Afiliaciones */
      {
        //OPTION
        path: "afiliado/gestion-afiliados",
        name: "GestionAfiliados",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.GESTION_AFILIADOS
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import("../views/modules/afiliaciones/afiliados/GestionAfiliados.vue")
      },
      {
        //OPTION
        path: "afiliado/coberturas-especiales",
        name: "CoberturasEspeciales",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.COBERTURAS_ESPECIALES
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/afiliaciones/afiliados/CoberturasEspeciales.vue"
          )
      },
      {
        //prestaciones configuracion
        path: "prestaciones/configuracion",
        name: "ConfiguracionPrestaciones",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Prestaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.CONFIGURACION_PRESTACIONES
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "@/views/modules/prestaciones/configuracion/ConfiguracionPrestaciones.vue"
          )
      },
      {
        //Consulta de Topes por grupo de practica
        path: "prestaciones/topes-generales",
        name: "TopesGenerales",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Prestaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.TOPES_GENERALES
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "@/views/modules/prestaciones/configuracion/ConsultaTopesGenerales.vue"
          )
      },
      {
        //Importacion topes
        path: "prestaciones/procesos-importacion-topes",
        name: "ProcesosImportacionTopesGenerales",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Prestaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.PROCESOS_IMPORTACION_TOPES_GENERALES
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "@/components/modules/prestaciones/configuracion/ProcesosImportacionTopesGenerales.vue"
          )
      },
      {
        //Importacion topes
        path: "prestaciones/configuracion-parametros-topes-autorizacion",
        name: "ParametrosTopesAuto",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Prestaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.PARAM_TOPES_AUTO
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "@/components/modules/prestaciones/configuracion/ParametrosTopesAuto.vue"
          )
      },
      {
        //OPTION
        path: "afiliados/movimientos-masivos",
        name: "ConsultaMovMasivoPadrones",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.MOVIMIENTOS_MASIVOS
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/afiliaciones/afiliados/ConsultaMovMasivoPadrones.vue"
          )
      },
      {
        //OPTION
        path: "afiliado/tipos-cobertura-especial",
        name: "TipoCoberturaEspecial",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.TIPOS_COBERTURAS_ESPECIALES
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../components/modules/afiliaciones/afiliados/TipoCoberturaEspecial.vue"
          )
      },
      {
        //OPTION
        path: "afiliado/tipos-cobertura-especial/coseguros",
        name: "CosegurosEnTipoCoberturaEspecial",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.COSEGURO_TIPO_COBERTURA_ESPECIAL
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../components/modules/afiliaciones/afiliados/CoseguroEnTipoCoberturaEspecial.vue"
          )
      },
      {
        //OPTION
        path: "afiliado/topes-cobertura-especial",
        name: "TopesCoberturaEspecial",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.TOPES_COBERTURAS_ESPECIALES
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../components/modules/afiliaciones/afiliados/TopesCoberturaEspecial.vue"
          )
      },
      {
        //VIEW
        path: "afiliados/configuracion-movimientos-masivos",
        name: "ConfigMovMasivosPadrones",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.CONFIGURAR_PROCESO_MOV_MASIVO
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../components/modules/afiliaciones/afiliados/ConfigMovMasivosPadrones.vue"
          )
      },
      {
        //VIEW
        path: "afiliados/bajas-automaticas",
        name: "BajasAutomaticas",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.CONSULTAR_BAJAS_AUTOMATICAS
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import("../views/modules/afiliaciones/afiliados/BajasAutomaticas.vue")
      },
      {
        //VIEW
        path: "afiliados/configuracion-bajas-automaticas",
        name: "ConfigBajasAutomaticas",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.CONFIG_BAJAS_AUTOMATICAS
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../components/modules/afiliaciones/afiliados/ConfigBajasAutomaticas.vue"
          )
      },
      {
        //OPTION
        path: "afiliados/nuevo-proceso-movimientos-masivos",
        name: "NuevoMovMasivoPadrones",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.NUEVO_PROC_MOV_MASIVO
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../components/modules/afiliaciones/afiliados/NuevoMovMasivoPadrones.vue"
          )
      },
      {
        //VIEW
        path: "afiliado/carga-afiliado",
        name: "EditAfiliado",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "../components/modules/afiliaciones/afiliados/EditAfiliado.vue"
          )
      },
      {
        //VIEW
        path: "afiliado/afiliados-convenios-sin-padron",
        name: "AfiliadosAConveniosSPadron",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "../components/modules/afiliaciones/afiliados/AfiliadosAConveniosSPadron.vue"
          )
      },
      {
        //VIEW
        path: "afiliado/datos-complementarios-afiliado",
        name: "DatosComplementariosAfiliado",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.GESTION_AFILIADOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/afiliaciones/afiliados/DatosComplementariosAfiliado.vue"
          )
      },
      {
        //OPTION
        path: "afiliado/tabla-afiliados",
        name: "TablasAfiliados",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.TABLA_AFILIADOS
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/afiliaciones/afiliados/configuracion/TablaAfiliados.vue"
          )
      },
      {
        //VIEW
        path: "afiliado/tipos-de-documentos",
        name: "TiposDocumentos",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.TIPOS_DOCUMENTOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/afiliaciones/afiliados/configuracion/TiposDeDocumentos.vue"
          )
      },
      {
        //VIEW
        path: "afiliado/estados-civiles",
        name: "EstadosCiviles",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.ESTADOS_CIVILES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/afiliaciones/afiliados/configuracion/EstadosCiviles.vue"
          )
      },
      {
        //VIEW
        path: "afiliado/parentescos",
        name: "WebParentescos",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.WEB_PARENTESCOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/afiliaciones/afiliados/configuracion/WebParentescos.vue"
          )
      },
      {
        //VIEW
        path: "afiliado/motivos-de-baja",
        name: "MotivosDeBaja",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.MOTIVOS_DE_BAJA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/afiliaciones/afiliados/configuracion/MotivosDeBaja.vue"
          )
      },
      {
        //VIEW
        path: "afiliado/dependencias",
        name: "Dependencias",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.DEPENDENCIAS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/afiliaciones/afiliados/configuracion/Dependencias.vue"
          )
      },
      {
        //OPTION
        path: "afiliado/solicitudes-de-afiliacion",
        name: "SolicitudesDeAfiliacion",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.SOLICITUDES_DE_AFILIACION
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/afiliaciones/afiliados/configuracion/SolicitudesDeAfiliacion.vue"
          )
      },
      {
        //OPTION
        path: "afiliado/padron-mensual-sss",
        name: "PadronMensualSSS",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.PADRON_MENSUAL_SSS
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/afiliaciones/afiliados/configuracion/PadronMensualSSS.vue"
          )
      },
      {
        //VIEW
        path: "afiliado/tipos-de-solicitud",
        name: "TiposSolicitud",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.TIPO_DE_SOLICITUD
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/afiliaciones/afiliados/configuracion/TiposSolicitud.vue"
          )
      },

      {
        //VIEW
        path: "afiliado/vendedores",
        name: "Vendedores",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.VENDEDORES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/afiliaciones/afiliados/configuracion/Vendedores.vue"
          )
      },
      /* E-mail */
      {
        //OPTION
        path: "email/servidores-email",
        name: "ServidoresEmail",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "E-mail");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.SERVIDORES_EMAIL
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import("../views/modules/email/configuracion/ServidoresEmail.vue")
      },
      /* APLICACION MOVIL */
      {
        //VIEW
        path: "aplicacion-movil/enlaces-utiles",
        name: "EnlacesUtilesApp",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.ENLACES_UTILES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../views/modules/appBenef/configuracion/EnlacesUtiles.vue")
      },
      {
        //VIEW
        path: "aplicacion-movil/plantilla-carga-tramites",
        name: "PlantillaCargaTramites",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.PLANTILLA_CARGA_TRAMITES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/appBenef/configuracion/PlantillaCargaTramites.vue"
          )
      },
      {
        //VIEW --gral
        path: "aplicacion-movil/menu-tabs",
        name: "MenuTabs",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.MENU_TABS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../views/modules/appBenef/configuracion/MenuTabs.vue")
      },
      {
        //VIEW --configuracion menu y tabs separados
        path: "aplicacion-movil/menu-tabs-config",
        name: "MenuTabsSeparated",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.MENU_TABS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/appBenef/configuracion/MenuTabsSeparated.vue"
          )
      },
      {
        //VIEW --configuracion menu y tabs juntos
        path: "aplicacion-movil/menu-tabs-config",
        name: "MenuTabsJoin",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.MENU_TABS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../views/modules/appBenef/configuracion/MenuTabsJoin.vue")
      },
      {
        //VIEW
        path: "aplicacion-movil/formato-email",
        name: "FormatosEmailApp",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.FORMATOS_EMAIL_APP
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/views/modules/appBenef/configuracion/FormatosEmailApp.vue")
      },
      {
        //VIEW
        path: "aplicacion-movil/edit-email-app",
        name: "EditEmailApp",
        meta: { requiresAuth: true },
        props: true,
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          next();
        },
        component: () =>
          import("@/components/modules/appBenef/configuracion/EditEmailApp.vue")
      },
      {
        //VIEW
        path: "aplicacion-movil/parametros-generales",
        name: "ParametrosGeneralesApp",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.PARAMETROS_GENERALES_APP
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/views/modules/appBenef/configuracion/ParametrosGenerales.vue"
          )
      },
      {
        //VIEW
        path: "aplicacion-movil/boton-de-pago",
        name: "BotonDePago",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.BOTON_DE_PAGO
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/components/modules/appBenef/configuracion/BotonDePago.vue")
      },
      {
        //VIEW
        path: "aplicacion-movil/info-medios-pagos",
        name: "InformacionMediosdePagos",
        meta: { requiresAuth: true },
        props: true,
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          next();
        },
        component: () =>
          import(
            "@/components/modules/appBenef/configuracion/InfoMediosPagos.vue"
          )
      },
      {
        //VIEW
        path: "aplicacion-movil/editar-configuracion-carga-tramites",
        name: "ConfigCargaTramites",
        meta: { requiresAuth: true },
        props: true,
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          next();
        },
        component: () =>
          import(
            "../views/modules/appBenef/configuracion/ConfigCargaTramites.vue"
          )
      },
      {
        //VIEW
        path: "aplicacion-movil/configuracion-parentescos",
        name: "ParentescosApp",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.PARENTESCOS_APP
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/views/modules/appBenef/configuracion/Parentescos.vue")
      },
      {
        //VIEW
        path: "aplicacion-movil/configuracion-apps-publicadas",
        name: "ConfiguracionApps",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIGURACION_APP
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/appBenef/configuracion/ConfiguracionApps.vue"
          )
      },
      {
        //VIEW
        path: "aplicacion-movil/configuracion-token",
        name: "Token",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Aplicación móvil");
          store
            .dispatch("user/updateFrequencyByOptionCode", webSiteOptions.TOKEN)
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../views/modules/appBenef/configuracion/Token.vue")
      },
      {
        //OPTION
        path: "aplicacion-movil/configuracion",
        name: "ConfiguracionAppBenef",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.CONFIGURACION_APLICACION_MOVIL
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/appBenef/configuracion/ConfiguracionAppBenef.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/estados-actas-fiscalizacion",
        name: "EstadosActasFiscalizacion",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.ESTADOS_ACTAS_FISCALIZACION
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/cuotas/configuracion/EstadosActasFiscalizacion.vue"
          )
      },
      /* CUOTAS */
      {
        //OPTION
        path: "cuotas/ddjj-y-aportes",
        name: "DDJJyAportes",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.CONSULTA_DDJJ_APORTES
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import("../views/modules/cuotas/aportes/DDJJYAportes.vue")
      },

      {
        //OPTION
        path: "cuotas/topes-remuneraciones",
        name: "TopesEnRemuneraciones",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          // cdo ingresa por url

          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.TOPES_REMUNERACIONES
            )
            .then(() => {
              next();
            });
          // ingresa por opcion de menu
        },
        component: () =>
          import(
            "../views/modules/cuotas/configuracion/TopesEnRemuneraciones.vue"
          )
      },
      {
        //OPTION
        path: "solicitudes-afiliacion/cotizador",
        name: "Cotizador",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url

          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.COTIZADOR
            )
            .then(() => {
              next();
            });
          // ingresa por opcion de menu
        },
        component: () => import("../views/modules/afiliaciones/Cotizador.vue")
      },
      {
        //OPTION
        path: "solicitudes-afiliacion/configuracion-cotizador",
        name: "ConfiguracionCotizador",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          // cdo ingresa por url

          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIGURACION_COTIZADOR
            )
            .then(() => {
              next();
            });
          // ingresa por opcion de menu
        },
        component: () =>
          import(
            "../components/modules/afiliaciones/solicitudes-afiliacion/ConfiguracionCotizador.vue"
          )
      },
      {
        //VIEW
        path: "solicitudes-afiliacion/formatos-impresion",
        name: "FormatosImpresionCotizador",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.FORMATOS_IMPRESION_COTIZADOR
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/afiliaciones/solicitudes-afiliacion/FormatosImpresionCotizador.vue"
          )
      },
      {
        //VIEW
        path: "solicitudes-afiliacion/formatos-email",
        name: "FormatosEmailCotizador",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.FORMATOS_EMAIL_COTIZADOR
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/afiliaciones/solicitudes-afiliacion/FormatosEmailCotizador.vue"
          )
      },
      {
        // VIEW
        path: "solicitudes-afiliacion/formato-email-cotizador",
        name: "EditFormatoEmailCotizador",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          next();
        },
        component: () =>
          import(
            "../components/modules/afiliaciones/solicitudes-afiliacion/EditFormatoEmailCotizador.vue"
          )
      },
      {
        // VIEW
        path: "afiliaciones/formato-impresion-cotizador",
        name: "EditFormatoImpresionCotizador",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Afiliaciones");
          next();
        },
        component: () =>
          import(
            "../components/modules/afiliaciones/solicitudes-afiliacion/EditFormatoImpresionCotizador.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/configuracion-ddjj-y-aportes",
        name: "ConfiguracionDDJJAportes",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIGURACION_DDJJ_APORTES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/cuotas/aportes/ConfiguracionDDJJAportes.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/empresas",
        name: "EmpresasYSucursales",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.EMPRESAS
            )
            .then(() => {
              next();
            });
        },

        component: () =>
          import(
            "../views/modules/cuotas/configuracion/tablasDeAportes/EmpresasYSucursales.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/descuentos-aportes-y-contribuciones",
        name: "DescuentosAportesYContribuciones",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.DESCUENTOS_APORTES_CONTRIBUCIONES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/cuotas/aportes/DescuentosAportesYContribuciones.vue"
          )
      },

      {
        //VIEW
        path: "cuotas/valores-capitas-anses",
        name: "ValoresCapitaAnsesJubilados",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.VALORES_CAPITA_ANSES_JUBILACION
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/cuotas/aportes/ValoresCapitaAnsesJubilados.vue"
          )
      },

      {
        //VIEW
        path: "cuotas/valores-capitas-desempleo-anses",
        name: "ValoresCapitaAnsesDesempleo",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.VALORES_CAPITA_ANSES_DESEMPLEO
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/cuotas/aportes/ValoresCapitaAnsesDesempleo.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/tablas-sicoss",
        name: "TablasSicoss",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.TABLA_SICOSS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/components/modules/cuotas/aportes/TablasSicoss.vue")
      },

      {
        //VIEW
        path: "cuotas/conceptos-afip",
        name: "ConceptosAFIP",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONCEPTOS_AFIP
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/components/modules/cuotas/aportes/ConceptosAFIP.vue")
      },
      {
        //VIEW
        path: "cuotas/tipos-comprobante-cobro-aportes",
        name: "TiposComprobanteCobroAportes",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.TIPOS_COMPROBANTES_COBRO
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/cuotas/aportes/TiposComprobantesCobro.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/tablas-remuneraciones-minimas",
        name: "TablasRemuneracionesMinimas",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.TABLAS_REMUNERACIONES_MINIMAS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/cuotas/aportes/TablasRemuneracionesMinimas.vue"
          )
      },

      {
        //OPTION
        path: "cuotas/fiscalizacion-de-aportes",
        name: "FiscalizacionDeAportes",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.FISCALIZACION_DE_APORTES
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/cuotas/configuracion/FiscalizacionDeAportes.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/gestion-agentes-cuenta",
        name: "GestionAgenteCuenta",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.GESTION_AGENTES_CUENTA
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import("../views/modules/cuotas/agenteCuenta/GestionAgenteCuenta.vue")
      },
      {
        //VIEW
        path: "cuotas/carga-agentes-cuenta/:agectaId?",
        name: "CargaAgentesCuenta",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "@/components/modules/cuotas/agenteCuenta/CargaAgentesCuenta.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/formas-pago-y-entidades-cobranza",
        name: "FormasPagoYEntidadesCobranza",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.FORMAS_PAGO_ENTIDADES_COBRANZA
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/cuotas/configuracion/FormasPagoYEntidadesCobranza.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/procesos-devengamientos",
        name: "ProcesosDevengamientos",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.PROCESOS_DEVENGAMIENTOS
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/cuotas/devengamientos/ProcesosDevengamientos.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/devengamientos-y-cobranzas",
        name: "DevengamientosCobranzas",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.DEVENGAMIENTOS_COBRANZAS
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/cuotas/configuracion/DevengamientosYCobranzas.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/importes-devengar",
        name: "ImportesADevengar",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.IMPORTES_DEVENGAR
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import("../views/modules/cuotas/devengamientos/ImportesADevengar.vue")
      },
      {
        //OPTION
        path: "cuotas/conceptos-a-devengar",
        name: "ConceptosADevengar",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONCEPTOS_A_DEVENGAR
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/cuotas/devengamientos/ConceptosADevengar.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/procesos-importacion",
        name: "ProcesosImportacion",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.PROCESOS_DE_IMPORTACION
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/cuotas/devengamientos/ProcesosImportacion.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/procesos-actualizacion",
        name: "ProcesosActualizacion",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.PROCESOS_DE_ACTUALIZACION
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/cuotas/devengamientos/ProcesosActualizacion.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/nuevo-proceso-actualizacion",
        name: "NuevoProcesoActualizacion",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "../components/modules/cuotas/devengamientos/NuevoProcesoActualizacion.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/adicionales-extra",
        name: "AdicionalesExtra",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.ADICIONALES_EXTRA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../views/modules/cuotas/devengamientos/AdicionalesExtra.vue")
      },
      {
        //VIEW
        path: "cuotas/actualizacion-aportes-esperados",
        name: "ActualizacionAportesEsperados",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.APORTES_ESPERADOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/cuotas/devengamientos/ActualizacionAportesEsperados.vue"
          )
      },

      //Consulta tablas de Facturacion
      {
        //VIEW
        path: "prestadores/tablas-facturacion",
        name: "ConsultaTablaFacturacion",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Prestadores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONSULTA_TABLA_FAC
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/prestadores/ConsultaTablaFacturacion.vue"
          )
      },
      {
        //VIEW
        path: "prestadores/procesos-actualizacion-tablas-facturacion",
        name: "ActualizacionTablasFacturacion",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Prestadores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONSULTA_TABLA_FAC
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/prestadores/ActualizacionTablasFacturacion.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/ver-proceso-asignacion",
        name: "VerProcesoAsignacion",
        meta: { requiresAuth: true },
        component: () =>
          import("../components/modules/prestadores/VerProcesoAsignacion.vue")
      },

      {
        //VIEW
        path: "cuotas/ddjj-ingresos-topes-cuotas",
        name: "DDJJIngresosParaTopesEnCuotas",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.DDJJ_INGRESOS_TOPES_CUOTAS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/cuotas/devengamientos/DDJJIngresosParaTopesEnCuotas.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/importes-fijos-a-devengar",
        name: "ImportesFijosPorGrupoFliar",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.IMPORTES_FIJOS_POR_GRUPO_FLIAR
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/cuotas/devengamientos/ImportesFijosPorGrupoFliar.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/bonificaciones-recargos",
        name: "ConsultaBonifRecargo",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONSULTA_BONIFICACIONES_RECARGOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/cuotas/devengamientos/ConsultaBonifRecargo.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/procesos-importacion-bonificaciones-recargos",
        name: "ProcesosImportacionBonifRecargo",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.IMPORTACION_BONIFICACIONES_RECARGOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/cuotas/devengamientos/ProcesosImportacionBonifRecargo.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/ddjj-ingresos-topes-cuotas/configuracion",
        name: "ConfigDDJJIngresosParaTopesEnCuotas",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIG_DDJJ_INGRESOS_TOPES_CUOTAS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/cuotas/devengamientos/ConfigDDJJIngresosParaTopesEnCuotas.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/actualizacion-importes-fijos",
        name: "ProcesoActualizacionImportesFijos",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.ACTUALIZACION_IMPORTES_FIJOS_DEVENGAR
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/cuotas/devengamientos/ProcesoActualizacionImportesFijos.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/nuevo-proceso-actualizacion-importes-fijos",
        name: "NuevoProcesoActualizacionImportesFijos",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "../components/modules/cuotas/devengamientos/NuevoProcesoActualizacionImportesFijos.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/actualizacion-adicionales",
        name: "ProcesoActualizacionAdicionales",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.ACTUALIZACION_ADICIONALES_EXTRA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/cuotas/devengamientos/ProcesoActualizacionAdicionales.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/nuevo-proceso-actualizacion-adicionales",
        name: "NuevoProcesoActualizacionAdicionales",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "../components/modules/cuotas/devengamientos/NuevoProcesoActualizacionAdicionales.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/nueva-actualizacion-aportes-esperados",
        name: "NuevaActualizacionAportesEsperados",
        meta: { requiresAuth: true },
        // beforeEnter(to, from, next) {
        //   store.dispatch("user/changeActiveMenu", "Cuotas");
        //   // cdo ingresa por url
        //   if (!from.name) {
        //     store
        //       .dispatch(
        //         "user/updateFrequencyByOptionCode",
        //         "5353C046-E639-45E5-AA19-1C74A3DA6903"
        //       )
        //       .then(() => {
        //         next();
        //       });
        //     // ingresa por opcion de menu
        //   } else {
        //     next();
        //   }
        // },
        component: () =>
          import(
            "../views/modules/cuotas/devengamientos/NuevaActualizacionAportesEsperados.vue"
          )
      },
      {
        //OPTION
        path: "prestadores/nueva-actualizacion-tabla-facturacion",
        name: "NuevaActualizacionTablaFacturacion",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "../components/modules/prestadores/NuevaActualizacionTablaFacturacion.vue"
          )
      },
      {
        //OPTION
        path: "prestadores/asignacion-tabla-facturacion",
        name: "AsignacionTablaFac",
        meta: { requiresAuth: true },
        component: () =>
          import("../components/modules/prestadores/AsignacionTablaFac.vue")
      },
      {
        //OPTION
        path: "prestadores/nueva-tabla-facturacion",
        name: "NuevaTablaFacturacion",
        meta: { requiresAuth: true },
        component: () =>
          import("../components/modules/prestadores/NuevaTablaFacturacion.vue")
      },
      {
        //OPTION
        path: "prestadores/configuracion-nueva-actualizacion",
        name: "ConfiguracionTablasFacturacion",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "../components/modules/prestadores/ConfiguracionTablasFacturacion.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/tipos-interes-fiscalizacion",
        name: "TiposInteresDeudaEmpresa",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.TIPOS_INTERES_DEUDA_EMPRESA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/cuotas/configuracion/TiposInteresDeudaEmpresa.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/creacion-lote-deuda",
        name: "CreacionLoteDeuda",
        meta: { requiresAuth: true },
        // beforeEnter(to, from, next) {
        //   store.dispatch("user/changeActiveMenu", "Cuotas");
        //   // cdo ingresa por url
        //   if (!from.name) {
        //     store
        //       .dispatch(
        //         "user/updateFrequencyByOptionCode",
        //         "5353C046-E639-45E5-AA19-1C74A3DA6903"
        //       )
        //       .then(() => {
        //         next();
        //       });
        //     // ingresa por opcion de menu
        //   } else {
        //     next();
        //   }
        // },
        component: () =>
          import(
            "../components/modules/cuotas/devengamientos/CreacionLoteDeuda.vue"
          )
      },

      {
        //OPTION
        path: "cuotas/creacion-devengamiento-coseguro",
        name: "NuevoDevengCoseguro",
        meta: { requiresAuth: true },
        // beforeEnter(to, from, next) {
        //   store.dispatch("user/changeActiveMenu", "Cuotas");
        //   // cdo ingresa por url
        //   if (!from.name) {
        //     store
        //       .dispatch(
        //         "user/updateFrequencyByOptionCode",
        //         "5353C046-E639-45E5-AA19-1C74A3DA6903"
        //       )
        //       .then(() => {
        //         next();
        //       });
        //     // ingresa por opcion de menu
        //   } else {
        //     next();
        //   }
        // },
        component: () =>
          import(
            "../components/modules/cuotas/devengamientos/NuevoDevengCoseguro.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/administrar-vistas",
        name: "AdministrarVistas",
        meta: { requiresAuth: true },
        // beforeEnter(to, from, next) {
        //   store.dispatch("user/changeActiveMenu", "Cuotas");
        //   // cdo ingresa por url
        //   if (!from.name) {
        //     store
        //       .dispatch(
        //         "user/updateFrequencyByOptionCode",
        //         "5353C046-E639-45E5-AA19-1C74A3DA6903"
        //       )
        //       .then(() => {
        //         next();
        //       });
        //     // ingresa por opcion de menu
        //   } else {
        //     next();
        //   }
        // },
        component: () =>
          import(
            "../components/modules/cuotas/devengamientos/AdministrarVistas.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/enviar-lote-deuda",
        name: "EnviarLoteDeuda",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          next();
        },
        component: () =>
          import(
            "@/components/modules/cuotas/devengamientos/EnviarLoteDeuda.vue"
          )
      },

      {
        //VIEW
        path: "cuotas/cajeros",
        name: "Cajeros",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CAJEROS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/components/modules/cuotas/configuracion/Cajeros.vue")
      },
      {
        //VIEW
        path: "cuotas/reglas-comerciales",
        name: "ReglasComerciales",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.REGLAS_COMERCIALES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/cuotas/configuracion/ReglasComerciales.vue"
          )
      },
      {
        //VIEW
        path: "reglas-comerciales/variables",
        name: "VariablesComerciales",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.VARIABLES_REGLAS_COMERCIALES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/cuotas/configuracion/VariablesComerciales.vue"
          )
      },

      {
        //VIEW
        path: "reglas-comerciales/procesos-actualizacion-variables",
        name: "ProcesosActualizacionVariables",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.PROCESOS_ACTUALIZACION_VARIABLES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/views/modules/cuotas/configuracion/ProcesosActualizacionVariables.vue"
          )
      },
      {
        //OPTION
        path: "reglas-comerciales/nuevo-proceso-actualizacion-variables",
        name: "NuevoProcesoActualizacionVariables",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "@/views/modules/cuotas/configuracion/NuevoProcesoActualizacionVariables.vue"
          )
      },
      // {
      //   //VIEW
      //   path: "reglas-comerciales/nuevo-proceso-actualizacion-variables",
      //   name: "NuevoProcesoActualizacionVariables",
      //   meta: { requiresAuth: true },
      //   beforeEnter(to, from, next) {
      //     store.dispatch("user/changeActiveMenu", "Cuotas");
      //     store
      //       .dispatch(
      //         "user/updateFrequencyByOptionCode",
      //         webSiteOptions.NUEVO_PROCESO_ACTUALIZACION_VARIABLES
      //       )
      //       .then(() => {
      //         next();
      //       });
      //   },
      //   component: () =>
      //     import("@/views/modules/cuotas/configuracion/NuevoProcesoActualizacionVariables.vue")
      // },
      {
        //VIEW
        path: "cuotas/condiciones-iva",
        name: "CondicionesIva",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONDICIONES_DE_IVA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/components/modules/cuotas/configuracion/CondicionesIva.vue")
      },
      {
        //VIEW
        path: "cuotas/conceptos",
        name: "Conceptos",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONCEPTOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/components/modules/cuotas/configuracion/Conceptos.vue")
      },
      {
        //VIEW
        path: "cuotas/tipos-comprobantes",
        name: "TiposComprobantes",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.TIPOS_DE_COMPROBANTES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../views/modules/cuotas/configuracion/TiposComprobantes.vue")
      },
      {
        //VIEW
        path: "cuotas/grupos-devengamiento",
        name: "GruposDevengamiento",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.GRUPOS_DE_DEVENGAMIENTO
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/cuotas/configuracion/GruposDevengamiento.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/entidades-financieras",
        name: "EntidadesFinancieras",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");

          store

            .dispatch(
              "user/updateFrequencyByOptionCode",

              webSiteOptions.ENTIDADES_FINANCIERAS
            )

            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/views/modules/cuotas/configuracion/EntidadesFinancieras")
      },
      {
        //VIEW
        path: "cuotas/cobradores",
        name: "Cobradores",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.COBRADORES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/components/modules/cuotas/configuracion/Cobradores.vue")
      },
      {
        //VIEW
        path: "cuotas/configuracion-devengamiento-coseguros",
        name: "ConfigDevengamientoCoseguros",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.DEVENGAMIENTO_COSEGUROS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/cuotas/configuracion/ConfigDevengamientoCoseguros.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/devengamiento-coseguros",
        name: "DevengamientoCosegurosWeb",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.DEVENG_COSEGUROS_WEB
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/cuotas/devengamientos/DevengamientoCosegurosWeb.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/devengamiento-adicionales-extra",
        name: "DevengamientoAdicionalesExtra",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.DEVENG_ADIC_EXTRA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/cuotas/devengamientos/DevengamientoAdicionalesExtra.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/procesos-cobranza",
        name: "ProcesosCobranza",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.PROCESOS_COBRANZA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/cuotas/configuracion/ProcesosCobranza.vue"
          )
      },
      {
        //VIEW
        path: "afiliado/tipos-de-beneficiarios",
        name: "TiposBeneficiarios",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "afiliaciones");

          store

            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.TIPOS_BENEFICIARIOS
            )

            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/afiliaciones/afiliados/configuracion/TiposBeneficiarios.vue"
          )
      },

      {
        //VIEW
        path: "afiliado/nacionalidades",
        name: "Nacionalidades",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "afiliaciones");

          store

            .dispatch(
              "user/updateFrequencyByOptionCode",

              webSiteOptions.NACIONALIDADES
            )

            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/afiliaciones/afiliados/configuracion/Nacionalidades.vue"
          )
      },
      {
        //VIEW
        path: "afiliado/obras-sociales-del-rnos",
        name: "RnosObrasSociales",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "afiliaciones");

          store

            .dispatch(
              "user/updateFrequencyByOptionCode",

              webSiteOptions.RNOS_OBRAS_SOCIALES
            )

            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/afiliaciones/afiliados/configuracion/RnosObrasSociales.vue"
          )
      },
      {
        //VIEW
        path: "afiliado/gerenciadoras-niveles-atencion",
        name: "GerenciadorasYNivelesAtencion",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "afiliaciones");

          store

            .dispatch(
              "user/updateFrequencyByOptionCode",

              webSiteOptions.GERENCIADORAS_NIVELES_ATENCION
            )

            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/afiliaciones/afiliados/configuracion/GerenciadorasYNivelesAtencion.vue"
          )
      },

      {
        //VIEW
        path: "afiliado/otras-afiliaciones",
        name: "OtrasAfiliaciones",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "afiliaciones");

          store

            .dispatch(
              "user/updateFrequencyByOptionCode",

              webSiteOptions.TIPOS_AFILIACION
            )

            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/afiliaciones/afiliados/configuracion/OtrasAfiliaciones.vue"
          )
      },

      {
        //VIEW
        path: "cuotas/vencimientosDDJJ",
        name: "DDJJVencimientoCuit",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");

          store

            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.VENCIMIENTO_CUIT
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/cuotas/configuracion/DDJJVencimientoCuit.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/devengamientos-cuotas",
        name: "DevengamientosCuotas",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.DEVENGAMIENTOS_CUOTAS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/cuotas/configuracion/DevengamientosCuotas.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/procesar-cobranzas",
        name: "ProcesarCobranzas",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.PROCESAR_COBRANZAS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../views/modules/cuotas/caja/ProcesarCobranzas.vue")
      },
      {
        //VIEW
        path: "cuotas/nuevo-envio-entidades-cobranza",
        name: "NuevoEnvioAEntidadCobranza",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          next();
        },
        component: () =>
          import(
            "@/components/modules/cuotas/configuracion/NuevoEnvioAEntidadCobranza.vue"
          )
      },

      {
        //VIEW
        path: "cuotas/edit-acta-fiscalizacion",
        name: "EditActa",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          next();
        },
        component: () =>
          import("@/components/modules/cuotas/aportes/EditActa.vue")
      },
      {
        //OPTION
        path: "cuotas/procesos-fiscalizacion",
        name: "GestiónProcesosFiscalización",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.GESTION_PROCESOS_FISCALIZACION
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/cuotas/aportes/GestionProcesosFiscalizacion.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/proceso-fiscalizacion/:procesoId?",
        name: "NuevoProcesoFiscalizacion",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          next();
        },
        component: () =>
          import(
            "@/components/modules/cuotas/aportes/NuevoProcesoFiscalizacion.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/procesos-fiscalizacion-afip",
        name: "GestionProcesoInformeAfip",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.GESTION_PROCESOS_INFORME_AFIP
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/cuotas/aportes/GestionProcesoInformeAfip.vue"
          )
      },
      {
        //VIEW (nuevo proceso)
        path: "cuotas/proceso-fiscalizacion-afip",
        name: "ProcesoInformeAFIP",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          next();
        },
        component: () =>
          import("@/components/modules/cuotas/aportes/ProcesoInformeAFIP.vue")
      },
      {
        //VIEW
        path: "cuotas/formatos-email-actas-fiscalizacion",
        name: "ConfiguracionFormatosEmailActa",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIGURACION_FORMATOS_EMAIL_ACTA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../views/modules/cuotas/configuracion/FormatosEmailActa.vue")
      },
      {
        //VIEW
        path: "cuotas/formatos-pdf-actas-fiscalizacion",
        name: "ConfiguracionFormatosPdfActa",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIGURACION_FORMATOS_PDF_ACTA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../views/modules/cuotas/configuracion/FormatosPdfActa.vue")
      },
      {
        // VIEW
        path: "cuotas/formato-email-acta",
        name: "NuevoFormatoEmail",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          next();
        },
        component: () =>
          import(
            "@/components/modules/cuotas/configuracion/NuevoFormatoEmail.vue"
          )
      },
      {
        // VIEW
        path: "cuotas/formato-pdf-acta",
        name: "NuevoFormatoPdf",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          next();
        },
        component: () =>
          import(
            "@/components/modules/cuotas/configuracion/NuevoFormatoPdf.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/envios-entidades-cobranzas",
        name: "EnviosEntidadesCobranzas",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.ENVIOS_ENTIDADES_COBRANZAS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/cuotas/configuracion/EnviosEntidadesCobranzas.vue"
          )
      },
      {
        //OPTION
        path: "cuotas/lotes-deuda",
        name: "LotesDeDeuda",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                "EFFA5E8A-EA32-4F64-B92B-CEB483CDD6A3"
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import("../views/modules/cuotas/devengamientos/LotesDeDeuda.vue")
      },
      {
        // VIEW
        path: "cuotas/detalle-comprobante",
        name: "DetalleComprobante",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          next();
        },
        component: () =>
          import(
            "@/components/modules/cuotas/devengamientos/DetalleComprobante.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/formas-de-pago",
        name: "FormasPago",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.FORMAS_PAGO
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../views/modules/cuotas/configuracion/FormasDePago.vue")
      },

      {
        //VIEW
        path: "cuotas/carga-aportes",
        name: "CargaAportes",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CARGA_APORTES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/cuotas/aportes/GestionDistribucionAportesWeb.vue"
          )
      },
      {
        //VIEW
        path: "cuotas/nuevo-proceso-distribucion-aportes",
        name: "NuevoProcesoDistribucionAportes",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Cuotas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.NUEVO_PROCESO_DISTRIBUCION_APORTES_WEB
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/cuotas/aportes/NuevoProcesoDistribucionAportes.vue"
          )
      },
      /* MESA DE ENTRADA */
      {
        //OPTION
        path: "mesa-entrada/configuracion",
        name: "ConfiguracionMesaEntrada",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Mesa de entrada");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.CONFIGURACION_MESA_ENTRADA
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/mesaEntrada/configuracion/ConfiguracionMesaEntrada.vue"
          )
      },
      {
        // VIEW
        path: "mesa-entrada/sectores",
        name: "Sectores",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Mesa de entrada");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.SECTORES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../views/modules/mesaEntrada/configuracion/Sectores.vue")
      },
      {
        // VIEW
        path: "mesa-entrada/destinos-despachos",
        name: "DestinosDespachos",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Mesa de entrada");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.DESTINOS_DESPACHOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/mesaEntrada/configuracion/DestinosDespachos.vue"
          )
      },
      {
        // VIEW
        path: "mesa-entrada/generadores-expedientes",
        name: "GeneradoresExpedientes",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Mesa de entrada");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.GENERADORES_EXPEDIENTES_MESA_ENTRADA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/mesaEntrada/configuracion/GeneradoresExpedientes.vue"
          )
      },
      {
        //VIEW
        path: "mesa-entrada/tipos-de-expedientes",
        name: "TiposDeExpedientesApp",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Mesa de entrada");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.TIPOS_DE_EXPEDIENTES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/appBenef/configuracion/TiposDeExpedientes.vue"
          )
      },
      {
        //VIEW
        path: "mesa-entrada/tipos-generadores-expedientes",
        name: "TiposGeneradores",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Mesa de entrada");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.TIPOS_GENERADORES_MESA_ENTRADA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/views/modules/mesaEntrada/configuracion/TiposGeneradores.vue"
          )
      },
      /* MESA DE ENTRADA */
      {
        //OPTION
        path: "mesa-entrada/log-sincronizacion-geclisa",
        name: "LogSincronizacionGeclisa",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Mesa de entrada");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.LOG_SINCRONIZACION_GECLISA
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/mesaEntrada/expedientes/LogSincronizacionGeclisa.vue"
          )
      },
      /* PROVEEDORES */
      {
        //OPTION
        path: "proveedores/ordenes-de-pago",
        name: "OrdenesDePago",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.ORDENES_DE_PAGO
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import("../views/modules/proveedores/ordenesPago/OrdenesDePago.vue")
      },
      {
        //OPTION
        path: "aplicacion-movil/registros",
        name: "CantidadRegistrosApp",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "AplicacionMovil");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.CANTIDAD_REGISTROS
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import("../views/modules/aplicacionMovil/CantidadRegistrosApp.vue")
      },
      {
        //VIEW
        path: "proveedores/ver-orden-pago",
        name: "VerOrdenDePago",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "@/components/modules/proveedores/ordenesPago/VerOrdenDePago.vue"
          )
      },
      {
        //VIEW
        path: "proveedores/proceso-masivo-ordenes-pago",
        name: "ConsultaProcesosMasivosOP",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONSULTA_PROCESOS_MASIVOS_OP
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/proveedores/ordenesPago/ConsultaProcesosMasivosOP.vue"
          )
      },
      {
        //VIEW
        path: "proveedores/configuracion-ordenes-de-pago",
        name: "ConfiguracionOrdenesPago",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIGURACION_ORDENES_DE_PAGO
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/proveedores/ordenesPago/ConfiguracionOrdenesPago.vue"
          )
      },
      {
        //VIEW
        path: "proveedores/formatos-impresion",
        name: "FormatosImpresionOrdenesPago",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.FORMATOS_IMPRESION_ORDENES_DE_PAGO
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/proveedores/ordenesPago/FormatosImpresionOrdenesPago.vue"
          )
      },
      {
        // VIEW
        path: "proveedores/formato-impresion-ordenes-pago",
        name: "EditFormatoImpresionOrdenesPago",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          next();
        },
        component: () =>
          import(
            "@/components/modules/proveedores/ordenesPago/EditFormatoImpresionOrdenesPago.vue"
          )
      },
      {
        //VIEW
        path: "proveedores/formatos-email",
        name: "FormatosEmailOrdenesPago",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.FORMATOS_EMAIL_ORDENES_DE_PAGO
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/proveedores/ordenesPago/FormatosEmailOrdenesPago.vue"
          )
      },
      {
        // VIEW
        path: "proveedores/formato-email-ordenes-pago",
        name: "EditFormatoEmailOrdenesPago",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          next();
        },
        component: () =>
          import(
            "@/components/modules/proveedores/ordenesPago/EditFormatoEmailOrdenesPago.vue"
          )
      },
      {
        //VIEW
        path: "proveedores/formatos-exportacion",
        name: "FormatosExportacionTransferencias",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.FORMATOS_EXPORTACION_TRANSFERENCIAS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../components/modules/proveedores/ordenesPago/FormatosExportacionTransferencias.vue"
          )
      },
      {
        // VIEW
        path: "proveedores/formato-exportacion-ordenes-pago",
        name: "EditFormatoExportacionTransferencia",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          next();
        },
        component: () =>
          import(
            "@/components/modules/proveedores/ordenesPago/EditFormatoExportacionTransferencia.vue"
          )
      },
      {
        //OPTION -- comprobantes proveedor
        path: "proveedores/comprobantes-proveedor",
        name: "ComprobantesProveedor",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.COMPROBANTES_PROVEEDOR
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/proveedores/comprobantesProveedor/ComprobantesProveedor.vue"
          )
      },
      {
        //OPTION
        path: "proveedores/autorizacion-facturas-proveedor",
        name: "AutorizacionesProveedor",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.AUTORIZACION_FACTURAS_PROVEEDOR
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/proveedores/comprobantesProveedor/AutorizacionesProveedor.vue"
          )
      },
      {
        //OPTION
        path: "proveedores/config-autorizacion-facturas",
        name: "ConfiguracionAutorizacionesProveedor",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.AUTORIZACION_FACTURAS_PROVEEDOR_CONFIG
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/proveedores/comprobantesProveedor/ConfiguracionAutorizacionesProveedor.vue"
          )
      },
      {
        //OPTION -- comprobantes proveedor
        path: "proveedores/importacion-factura",
        name: "ImportacionFacturas",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.IMPORTACION_FACURA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/proveedores/comprobantesProveedor/ImportacionFacturas.vue"
          )
      },
      {
        //OPTION
        path: "proveedores/cuenta-corriente",
        name: "CuentaCorrienteProveedores",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.CUENTA_CORRIENTE_PROVEEDORES
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/proveedores/cuentaCorriente/CuentaCorrienteProveedores.vue"
          )
      },
    
      {
        // VIEW
        path: "proveedores/cuenta-corriente/carga-facturas",
        name: "CargaFacturasProveedores",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Proveedores");
          next();
        },
        component: () =>
          import(
            "../views/modules/proveedores/cuentaCorriente/CargaFacturasProveedores.vue"
          )
      },
      {
        //OPTION
        path: "proveedores/ver-estado-factura",
        name: "EstadoFacturaPrestador",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "@/components/modules/proveedores/cuentaCorriente/EstadoFacturaPrestador.vue"
          )
      },
      {
        //OPTION
        path: "proveedores/ver-comprobante-proveedores",
        name: "DetalleComprobanteProv",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "@/components/modules/proveedores/cuentaCorriente/DetalleComprobanteProv.vue"
          )
      },
      //CONVENIOS
      {
        //OPTION
        path: "convenios/configuracion",
        name: "ConfiguracionConvenios",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Convenios");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.CONFIGURACION_CONVENIOS
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/convenios/configuracion/ConfiguracionConvenios.vue"
          )
      },
      {
        //OPTION
        path: "convenios/relacion-convenios-empresas",
        name: "RelacionConveniosEmpresas",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Convenios");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.RELACION_CONVENIO_EMPRESA
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/convenios/configuracion/RelacionConveniosEmpresas.vue"
          )
      },
      //Planes
      {
        //OPTION
        path: "convenios/planes",
        name: "ConveniosPlanes",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Convenios");
          store
            .dispatch("user/updateFrequencyByOptionCode", webSiteOptions.PLANES)
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/components/modules/convenios/configuracion/Planes.vue")
      },

      {
        //OPTION
        path: "convenios/procesos-desregulacion",
        name: "ConsultaProcesosDesregulacion",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Convenios");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.PROCESOS_DESREGULACION
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "../views/modules/convenios/procesosDesregulacion/ConsultaProcesosDesregulacion.vue"
          )
      },
      {
        //VIEW
        path: "convenios/configuracion-proceso-desregulacion",
        name: "ConfigProcesosDesregulacion",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Convenios");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIG_CONCEPTO_DESREGULACION
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/convenios/procesosDesregulacion/ConfigProcesosDesregulacion.vue"
          )
      },
      {
        //VIEW
        path: "convenios/configuracion-comisiones",
        name: "ConfigComisionesConcDesregulacion",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Convenios");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIG_COMISIONES_CONCEPTOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/convenios/procesosDesregulacion/ConfigComisionesConcDesregulacion.vue"
          )
      },
      //ADMINISTRACION DE SISTEMAS
      /* Configuracion - PermisoS */
      {
        //OPTION
        path: "administracion-sistema/configuracion",
        name: "ConfiguracionAdmSistema",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Administración del sistema");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.CONFIGURACION_ADM_SISTEMA
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import(
            "@/views/modules/administracion-sistema/configuracion/ConfiguracionAdmSistema.vue"
          )
      },
      {
        //VIEW
        path: "administracion-sistema/permisos",
        name: "ConfiguracionPermisos",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Administración del sistema");

          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIGURACION_PERMISOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/administracion-sistema/ConfiguracionPermisos.vue"
          )
      },

      {
        //VIEW
        path: "administracion-sistema/pantalla-login",
        name: "PantallaLoginAdministracion",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Administración del sistema");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.PANTALLA_LOGIN_ADMINISTRACION
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/administracion-sistema/PantallaLoginAdministracion.vue"
          )
      },
      {
        //VIEW
        path: "mesa-entrada/configuracion-replicacion",
        name: "ConfigReplicacion",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Administración del sistema");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIGURACION_REPLICACION
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/administracion-sistema/ConfigReplicacion.vue"
          )
      },
      // {
      //   //VIEW
      //   path: "administracion-sistema/configuracion-proceso",
      //   name: "ConfigProceso",
      //   meta: { requiresAuth: true },
      //   async beforeEnter(to, from, next) {
      //     store.dispatch("user/changeActiveMenu", "Administración del sistema");
      //     store
      //       .dispatch(
      //         "user/updateFrequencyByOptionCode",
      //         webSiteOptions.PANTALLA_LOGIN_ADMINISTRACION
      //       )
      //       .then(() => {
      //         next();
      //       });
      //   },
      //   component: () =>
      //     import(
      //       "@/components/modules/administracion-sistema/ConfigProceso.vue"
      //     )
      // },
      /* ANALYTICS */
      {
        //VIEW AFILIACIONES
        path: "analytics/afiliaciones",
        name: "AnalyticsContainerAfiliaciones",
        meta: { requiresAuth: true },
        props: {
          title: "Afiliaciones",
          optionCode: webSiteOptions.ANALYTICS_AFILIACIONES
        },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Analytics");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.ANALYTICS_AFILIACIONES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/views/modules/analytics/AnalyticsContainer.vue")
      },
      {
        //VIEW PRESTACIONES
        path: "analytics/prestaciones",
        name: "AnalyticsContainerPrestaciones",
        meta: { requiresAuth: true },
        props: {
          title: "Prestaciones",
          optionCode: webSiteOptions.ANALYTICS_PRESTACIONES
        },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Analytics");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.ANALYTICS_PRESTACIONES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/views/modules/analytics/AnalyticsContainer.vue")
      },
      {
        //VIEW INGRESOS
        path: "analytics/ingresos",
        name: "AnalyticsContainerIngresos",
        meta: { requiresAuth: true },
        props: {
          title: "Ingresos",
          optionCode: webSiteOptions.ANALYTICS_INGRESOS
        },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Analytics");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.ANALYTICS_INGRESOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/views/modules/analytics/AnalyticsContainer.vue")
      },
      {
        //VIEW AFILIACIONES - PRESTACIONES E INGRESOS
        path: "analytics/afiliaciones-prestaciones-ingresos",
        name: "AnalyticsContainerAfiliacionesPrestacionesIngresos",
        meta: { requiresAuth: true },
        props: {
          title: "Afiliaciones - Prestaciones e Ingresos",
          optionCode:
            webSiteOptions.ANALYTICS_AFILIACIONES_PRESTACIONES_INGRESOS
        },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Analytics");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.ANALYTICS_AFILIACIONES_PRESTACIONES_INGRESOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/views/modules/analytics/AnalyticsContainer.vue")
      },
      {
        //VIEW OTROS TABLEROS UNITARIO
        path: "analyticsTable/:nameTable",
        name: "AnalyticsTable",
        meta: { requiresAuth: true },
        component: () =>
          import("@/views/modules/analytics/AnalyticsContainer.vue")
      },
      {
        //VIEW CONFIGURACION ANALYTICS
        path: "analytics/configuracion",
        name: "AnalyticsConfig",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Analytics");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIGURACION_TABLEROS_ANALYTICS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("@/views/modules/analytics/AnalyticsConfiguracion.vue")
      },
      {
        // VIEW OTROS TABLEROS COMPONENT ANALYTICS
        path: "analytics/otros-tableros",
        name: "OtrosTablerosAnalytics",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Analytics");
          // cdo ingresa por url
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.ANALYTICS_OTROS_TABLEROS
              )
              .then(() => {
                next();
              });
            // ingresa por opcion de menu
          } else {
            next();
          }
        },
        component: () =>
          import("../views/modules/analytics/OtrosTablerosAnalytics.vue")
      },
      /* MONEDAS */
      {
        //VIEW MONEDAS
        path: "administracion-sistema/monedas",
        name: "MonedasGeneral",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Administración del sistema");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.MONEDAS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/views/modules/administracion-sistema/configuracion/Monedas.vue"
          )
      },
      {
        //VIEW DOBLE FACTOR AUTENTICACIÓN
        path: "administracion-sistema/doble-factor-autenticacion",
        name: "DobleFactorAutenticacion",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Administración del sistema");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.DOBLE_FACTOR_AUTENTICACION
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/administracion-sistema/DobleFactorAutenticacion.vue"
          )
      },
      {
        //VIEW GESTIÓN USUARIOS
        path: "administracion-sistema/gestion-usuarios",
        name: "GestionUsuarios",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Administración del sistema");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.GESTION_USUARIOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/administracion-sistema/GestionUsuarios.vue"
          )
      },
      {
        //VIEW POLÍTICA DE CONTRASEÑAS
        path: "administracion-sistema/politica-contraseñas",
        name: "PoliticaContraseñas",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Administración del sistema");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.POLITICA_CONTRASEÑAS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/administracion-sistema/PoliticaContraseñas.vue"
          )
      },
      {
        //VIEW  TAREAS PROGRAMADAS MANTENIMIENTO
        path: "administracion-sistema/tareas-programadas",
        name: "TareasProgramadas",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Administración del sistema");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.TAREAS_PROGRAMADAS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/views/modules/administracion-sistema/configuracion/ConfiguracionTareasProgramadas.vue"
          )
      },
      {
        //VIEW COTIZACIONES DE MONEDAS
        path: "administracion-sistema/cotizaciones",
        name: "CotizacionesMonedas",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Monedas");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.COTIZACIONES_MONEDAS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/administracion-sistema/monedas/CotizacionesMonedas.vue"
          )
      },
      {
        //VIEW  Configuraciones generales
        path: "administracion-sistema/configuraciones-generales",
        name: "ConfiguracionesGenerales",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Administración del sistema");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONFIGURACIONES_GENERALES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/views/modules/administracion-sistema/configuracion/ConfiguracionesGenerales.vue"
          )
      },
      {
        //VIEW LOG CONFIGURACIONES GENERALES
        path: "administracion-sistema/log-configuraciones-generales",
        name: "LogConfiguracionesGenerales",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Administración del sistema");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.LOG_CONFIGURACIONES_GENERALES
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import(
            "@/components/modules/administracion-sistema/LogConfiguracionesGenerales.vue"
          )
      },
      /* Facturacion OS */
      {
        //OPTION -- cuenta corriente facturacion OS
        path: "facturacion-os/cuenta-corriente",
        name: "CuentaCorrienteFacturacionOS",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Facturación OS");
          if (!from.name) {
            store
              .dispatch(
                "user/updateFrequencyByOptionCode",
                webSiteOptions.CUENTA_CORRIENTE_FACTURACIONOS
              )
              .then(() => {
                next();
              });
          } else {
            next();
          }
        },
        component: () =>
          import(
            "../views/modules/facturacionOS/CuentaCorrienteFacturacionOS.vue"
          )
      },
      {
        //OPTION -- comprobantes facturacion OS
        path: "facturacion-os/comprobantes-facturacionOS",
        name: "ComprobantesFacturacionOS",
        meta: { requiresAuth: true },
        async beforeEnter(to, from, next) {
          store.dispatch("user/changeActiveMenu", "Facturación OS");
          store
            .dispatch(
              "user/updateFrequencyByOptionCode",
              webSiteOptions.CONSULTA_COMPROBANTES_FACTURACIONOS
            )
            .then(() => {
              next();
            });
        },
        component: () =>
          import("../views/modules/facturacionOS/ComprobantesFacturacionOS.vue")
      },
      {
        //OPTION
        path: "facturacionOS/ver-comprobante-facturacion",
        name: "DetalleComprobanteFacturacionOS",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "@/components/modules/facturacionOS/DetalleComprobanteFacturacionOS.vue"
          )
      },
      {
        //OPTION
        path: "facturacionOS/ver-comprobante-cancelatorio-facturacion",
        name: "DetalleComprobanteCancelaFacturacionOS",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "@/components/modules/facturacionOS/DetalleComprobanteCancelaFacturacionOS.vue"
          )
      },
      /* PARA IR A MVC */
      {
        path: "mvc/:url",
        name: "mvc",
        component: () =>
          import("../views/modules/mvccontainer/MvcContainer.vue"),
        beforeEnter(to, from, next) {
          if (process.env.NODE_ENV == "development") next("/404");
          next();
        }
      },

      /* ERROR 404 */
      {
        path: "/404",
        alias: "*",
        name: "E404",
        component: () => import("../views/errors/E404.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  // linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // requiere auntenticacion
    if (
      !helper.getCookie("gecrosgestioncookie") &&
      !localStorage.getItem("token")
    ) {
      next({
        path: "/login" // usuario no loggeado
      });
    } else {
      next(); // usuario loggeado
    }
  } else {
    next(); // Pagina Pública
  }
});

export default router;
